<template>
  <div class="en_add">
    <el-card>
      <el-form
        ref="form"
        :model="form"
        :rules="rule"
        label-width="100px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="积分券名称" prop="name">
          <el-col :span="8">
            <el-input v-model="form.name" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="赠送积分" prop="bonus">
          <el-col :span="8">
            <el-input v-model="form.bonus" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="领取有效期" prop="beginTime">
          <div-date-picker
            :begin.sync="form.beginTime"
            :end.sync="form.endTime"
          ></div-date-picker>
        </el-form-item>
        <el-form-item label="积分有效期" prop="bonusEndTime">
          <el-col :span="8">
            <el-input-number size="small" v-model="form.bonusEndTime" :step="1"></el-input-number>
            天
            <!-- <br>
            <div class="warning" style="color:#fda400;line-height:30px">
            领取之日开始算
          </div> -->
          </el-col>
        </el-form-item>
        <el-form-item label="积分券数量" prop="number">
          <el-col :span="8">
            <el-input-number size="small" v-model="form.number" :step="1"></el-input-number>
          </el-col>
        </el-form-item>

        <el-form-item label="每人限领 " prop="limit">
          <el-input-number size="small" v-model="form.limit" :step="1"></el-input-number>
          每人可以领取多少张
          <br />
          <div class="warning" style="color:#fda400;line-height:30px">
            若不限次数，请填0
          </div>
        </el-form-item>
        <!-- <el-form-item label="类型">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">通用</el-radio>
            <el-radio :label="1">活动</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.type == 1">
          <el-form-item label="选择活动">
            <el-col :span="8">
              <fuzzy-selection
                ref="fuzzySelection"
                :value.sync="form.activityId"
                :type="4"
                clearable
                tipName="活动"
              />
            </el-col>
          </el-form-item>
          <el-form-item label="适用票种" prop="ticketIds">
            <el-checkbox-group v-model="form.ticketIds">
              <el-checkbox
                :label="item.id + ''"
                v-for="(item, index) in tickList"
                :key="index"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </template> -->
        <el-form-item label="描述">
          <el-col :span="8">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="form.desc"
            >
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="onSubmit">{{
            id ? '修改' : '提交'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import imgup from '@/components/upImg/index.vue'
import rules from '@/utils/rules'
import fuzzySelection from '@/components/fuzzySelection'
import divDatePicker from './components/div-date-picker.vue'
export default {
  components: {
    imgup,
    fuzzySelection,
    divDatePicker
  },
  data() {
    return {
      id: null,
      form: {
        name: '',
        bonusEndTime: 0,
        ticketIds: [],
        time: [],
        beginTime: '',
        endTime: '',
        number: '',
        limit: '',
        desc: '',
        bonus: '',
        type: 0
      },

      rule: {
        name: [rules.req('请输入积分券名称')],
        bonus: [rules.req('请输入赠送积分')],
        beginTime: [rules.req('请输入领取有效期')],
        bonusEndTime: [rules.req('请输入积分有效期')],
        number: [rules.req('请输入积分券数量')],
        limit: [rules.req('请输入每人限领数量')],
        type: [rules.req('请输入类型')],
        business: [rules.req('请输入行业')],
        serverArea: [rules.req('请输入业务区域')],
        companyDesc: [rules.req('请输入企业介绍')],
        intro: [rules.req('请输入企业简介')]
      },
      action: {
        action: 'bonus'
      },
      province: {},
      city: {},
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      cardList: [],
      tickList: [],
      first: true
    }
  },
  watch: {
   
  },
  computed: {},
  created() {
    sessionStorage.setItem('action', 'bonus')
    this.getform()
    // this.getEnv()
  },
  methods: {
    change(val) {
      this.form.desc = val
    },
   
    async onSubmit() {
      var url
      if (this.id) {
        url = '/admin/BonusCoupon/edit'
      } else {
        url = '/admin/BonusCoupon/add'
      }
    this.$refs.form.validate()
      var { data: res } = await this.$http.post(url, {
        ...this.form,
        id: this.id,
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.$router.push('/bonus_coupon_list')
      }
    },
    async getform() {
      if (!this.$route.query.id) {
        return
      }
      this.id = this.$route.query.id
      const { data: res } = await this.$http.get(
        '/admin/BonusCoupon/getById?id=' + this.id
      )
      if (res.errorCode == 200) {
        // console.log(res);
        // console.log(res.data);
        this.form = {
          ...res.data,
        }

        // this.$nextTick(() => {
        //   if (!this.$refs.fuzzySelection) {
        //     return
        //   }
        //   this.$refs.fuzzySelection.options = [
        //     { id: this.form.activityId, title: this.form.activityName }
        //   ]
        //   this.$refs.fuzzySelection.val = this.form.activityId
        // })
      }
      //  this.form.initViews =
    }

  }
}
</script>

<style lang="less">
.en_add {
  .box {
    display: inline-block;
    width: 300px;
    input {
      border-color: #dcdfe6 !important;
    }
  }
}
</style>

<template>
  <div>
    <el-date-picker
      v-model="time"
      value-format="yyyy-MM-dd HH:mm:ss"
      format="yyyy-MM-dd HH:mm:ss"
      type="datetimerange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  props: {
    begin: {
      type: String,
      default: ''
    },
    end: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    time: {
      get() {
        if (this.begin) {
          return [this.begin,this.end]
        }
        return ''
      },
      set(val) {
        this.$emit('update:begin', val[0])
        this.$emit('update:end', val[1])
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
